@import '../../scss/mixins';

.link {
  @include visit-button-text;
  line-height: 2;
  background-color: #009CBA;
  min-width: 225px;
  box-sizing: border-box;
  white-space: nowrap;
  margin-top: 20px;
  border-radius: 20px;
}