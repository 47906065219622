@import "./scss/mixins";

/* Markdown styles */
strong {
  font-weight: bold;
}
em {
  font-style: italic;
}
ul {
  list-style-type: disc;
}
ul ul {
  list-style-type: circle;
}
ul ul ul {
  list-style-type: square;
}
ol {
  list-style: decimal inside;
}

h2 {
  font-family: Rubik;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 5px;
}

ul li {
  margin-left: 10px;

  @media (min-width: 768px) {
    margin-left: 20px;
  }
}

/* functional */

.row-or-column {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;

    > * {
      flex: 1 0;
    }
  }
}

.flexbox-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

body,
#root {
  // overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

#root {
  .mobile-only {
    @media (min-width: 768px) {
      display: none;
    }
  }

  .desktop-tablet-only {
    @media (max-width: 767px) {
      display: none;
    }
  }

  .desktop-only {
    @media (max-width: 991px) {
      display: none;
    }
  }

  .mobile-tablet-only {
    @media (min-width: 992px) {
      display: none;
    }
  }
}

/* shared */

.page-intro-large {
  line-height: 32px;
  font-size: 24px;
  font-family: Georgia;
  letter-spacing: 0;
  margin: 24px 0;

  @media (min-width: 992px) {
    font-size: 34px;
    line-height: 48px;
  }
}

.page-intro-text {
  @include page-intro-text;
}

.content {
  box-sizing: border-box;
  padding: 10px 20px 0;
  text-align: left;
  font-weight: 300;
  font-family: Rubik;
  color: #404040;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

button {
  cursor: pointer;
}
