@import "../../scss/mixins";

.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  // background-color: rgba(0, 0, 0, 0.1);
  padding: 20px 0;
  box-sizing: border-box;
  
  display: -webkit-flex; 
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
  
  z-index: 1200;
  -webkit-flex-direction: column;
  flex-direction: column;

  @media (max-width: 767px) {
    overflow-y: scroll;
  }

  &.hidden { display: none; }

  .modal {
    background-color: white;
    // border: 1px solid pink;
    width: 500px;
    box-sizing: border-box;
    border-radius: 6px;
    position: relative;
    margin: auto 0px;

    height: 100%;
    width: calc(100vw - 40px);
    max-width: 728px;

    @media (min-width: 768px) {
      height: auto;
    }


    button.close-x {
      //make a transparent X
      background-image: url('./close-icon.png');
      background-color: transparent;
      background-size: contain;
      border: none;
      color: transparent;
      //positioning & sizing
      font-size: 0;
      position: absolute;
      right: -7px;
      top: -7px;
      padding: 15px;
    }

    .modal-content {
      display: inline-block;
      padding: 20px;
      box-sizing: border-box;
      font-family: Rubik;

      width: 100%;
      height: 100%;

      @media (max-width: 767px) {
        overflow-y: scroll;
      }

      p, li {
        @include page-intro-text;
        margin-top: 0;
        margin-bottom: 10px;
      }
    }
  }
}
