@import "../../scss/mixins";

#RainbowBar {
  @include app-max-size;
  display: flex;
  flex-direction: row;
  height: 15px;

  &.small {
    height: 5px;
  }

  > div {
    height: 100%;
    width: 16.67%;
  }

  .light-orange {
    background-color: #faac1d;
  }

  .orange {
    background-color: #f37928;
  }

  .light-green {
    background-color: #a2c800;
  }

  .green {
    background-color: #669900;
  }

  .light-blue {
    background-color: #83cfdd;
  }

  .blue {
    background-color: #009cba;
  }
}
