@import "../../scss/mixins";

#HeaderNav {
  @include app-max-size;
  background: white;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 5px;

  @media (min-width: 992px) {
    padding-left: 10px;
  }

  .title {
    margin: 15px 10px 0 20px;

    img {
      height: 50px;
    }
  }

  .menu {
    margin-left: 14px;
    text-align: left;
    font-size: 15px;
    font-family: Rubik;
    font-weight: normal;

    a hr {
      display: none;
    }

    // .home-link:hover ~ hr,
    hr.home {
      margin-left: 18px;
      background: #faac1d;

      @media (min-width: 992px) {
        margin-left: 24px;
      }
    }

    // .patient-link:hover ~ hr,
    hr.patients-regular,
    hr.patients-postpartum {
      margin-left: 100px;
      background: #f37928;

      @media (min-width: 992px) {
        margin-left: 112px;
      }
    }

    // .provider-link:hover ~ hr,
    hr.providers-regular,
    hr.providers-postpartum {
      margin-left: 255px;
      background: #669900;

      @media (min-width: 992px) {
        margin-left: 275px;
      }
    }

    // .about-link:hover ~ hr,
    hr.about {
      margin-left: 472px;
      background: #006477;

      @media (min-width: 992px) {
        margin-left: 505px;
      }
    }

    // .veteran-link:hover ~ hr,
    hr.veterans {
      margin-left: 392px;
      background: #009cba;

      @media (min-width: 992px) {
        margin-left: 420px;
      }
    }

    .home-link:hover,
    .home-link.home {
      color: #faac1d;
    }

    .patients-link:hover,
    .patients-link.patients-regular,
    .patients-link.patients-postpartum {
      color: #f37928;
    }

    .providers-link:hover,
    .providers-link.providers-regular,
    .providers-link.providers-postpartum {
      color: #669900;
    }

    .about-link:hover,
    .about-link.about {
      color: #006477;
    }

    .veteran-link:hover,
    .veteran-link.veterans {
      color: #009cba;
    }

    a {
      @include header-link;
    }

    hr {
      height: 0.25rem;
      width: 20px;
      margin: 0;
      border: 0;
      border-radius: 5px;
      transition: 0.3s ease-in-out;
      font-family: Rubik;
      font-weight: bold;
    }
  }
}
