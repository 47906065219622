@import "../../scss/mixins";

#Providers {
  @include app-max-size;
  @include accordion-section;

  @media (min-width: 992px) {
    align-items: flex-start;
  }

  .accordion-section.with-table {
    @include with-table;
  }

  img.desktop-menu {
    margin: 0px auto;
    width: 100%;

    @media (min-width: 768px) {
      margin: 0;
    }

    @media (min-width: 992px) {
      max-width: 500px;
      width: 40vw;
    }
  }

  .with-summary {
    img {
      width: 100%;
      @media (min-width: 992px) {
        max-width: 60vw;
      }
    }
  }

  .with-fertility.regular:before {
    @include with-fertility;
    background-image: url("../../assets/images/ipad-7-acceptance-va-fertility.jpg");
  }

  .with-topics {
    &:before {
      @include with-topics;
    }

    &.regular:before {
      background-image: url("../../assets/images/health-before-pregnancy-desktop.jpg");
    }
    &.postpartum:before {
      background-image: url("../../assets/images/health-between-pregnancies-desktop.jpg");
    }
  }
}
