@import "../../scss/mixins";

#Hamburger {
  @media (min-width: 768px) {
    display: none;
  }

  .mybc {
    position: absolute;
    top: 37px;
    left: 20px;

    &.in-menu {
      position: static;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-left: 20px;
      margin-top: 22px;
    }

    img {
      height: 45px;
    }
  }

  .top-bar.fixed {
    position: absolute;
    top: -15px;
  }

  .menu-item-container > div {
    display: flex;
    flex-direction: column;

    a {
      @include hamburger-link;
    }

    .sub-link {
      @media (max-width: 767px) {
        font-size: 16px;
        margin: 10px 0;
      }
    }
  }

  #home.home {
    color: #faac1d;

    hr {
      background: #faac1d;
    }
  }

  .patients-link.patients-regular,
  .patients-link.patients-postpartum {
    color: #f37928;

    ~ hr {
      background: #f37928;
    }
  }

  .providers-link.providers-regular,
  .providers-link.providers-postpartum {
    color: #669900;

    ~ hr {
      background: #669900;
    }
  }

  #about.about {
    color: #006477;

    hr {
      background: #006477;
    }
  }

  #veterans.veterans {
    color: #009cba;

    hr {
      background: #009cba;
    }
  }

  hr {
    height: 0.25rem;
    width: 20px;
    margin: 5px auto;
    border: none;
    //background: #F37928;
  }

  .bm-item {
    display: inline-block;
    // Our sidebar item styling
    text-decoration: none;
    margin-bottom: 10px;
    color: #d1d1d1;
    transition: color 0.2s;
  }

  // Change color on hover
  .bm-item:hover {
    color: white;
  }

  // The rest copied directly from react-burger-menu docs

  // Position and sizing of burger button
  .bm-burger-button {
    width: 60px !important;
    height: 60px;
    padding: 30px 30px 0 0;
    box-sizing: border-box;
    position: absolute;
    right: 0;
    top: 0;
  }

  // Position and sizing of clickable cross button
  .bm-cross-button {
    width: 30px !important;
    height: 30px !important;
    right: 34px !important;
    top: 22px !important;
  }

  // Color/shape of close button cross
  // .bm-cross {
  //   background: #bdc3c7;
  // }

  // General sidebar styles
  .bm-menu-wrap {
    top: 15px;

    .bm-menu {
      background: white;
      //padding: 2.5em 1.5em 0;
      font-size: 1.15em;
      height: auto !important;
    }
  }

  // Morph shape necessary with bubble or elastic
  .bm-morph-shape {
    fill: #373a47;
  }

  // Wrapper for item list
  .bm-item-list {
    color: #b8b7ad;
    display: flex;
    flex-direction: column;
  }

  // Styling of overlay
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
}
