// colors
$light-orange: #f5a623;
$dark-orange: #f37928;
$light-green: #a2c800;
$dark-green: #669900;
$light-blue: #83cfdd;
$dark-blue: #009cba;

@mixin page-intro-text {
  font-weight: 300;
  font-family: Rubik;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 28px;
  width: 100%;

  @media (min-width: 992px) {
    font-size: 18px;
    line-height: 24px;
  }

  p,
  > ul {
    margin-bottom: 10px;
  }
}

@mixin app-max-size {
  width: 100%;
  box-sizing: border-box;

  @media (min-width: 1280px) {
    width: 1280px;
    margin: 0 auto;
  }
}

@mixin right-float-image {
  content: "";
  display: block;
  float: right;
  background-repeat: no-repeat;
  background-size: contain;
}

@mixin with-summary {
  @include right-float-image;
  background-image: url("../assets/images/summary-tablet.png");
  width: 90vw;
  height: 90vw;

  @media (min-width: 768px) {
    width: 377px;
    height: 358px;
    background-size: 377px 358px;
  }
}

@mixin with-fertility {
  @include right-float-image;
  width: 90vw;
  height: 90vw;
  max-height: 595px;
  max-width: 711px;

  @media (min-width: 768px) {
    width: 356px;
    height: 356px;
    background-size: 356px 298px;
  }
}

@mixin with-topics {
  @include right-float-image;
  width: 90vw;
  height: 60.4929vw;

  @media (min-width: 768px) {
    width: 426px;
    height: 286.33px;
    background-size: 426px 286.33px;
  }
}

@mixin with-table {
  .table-heading,
  .table-footer,
  th,
  td {
    padding: 5px;
    font-size: 16px;
    line-height: 1.25;
  }

  .table-heading,
  .table-footer,
  table {
    width: 100%;
    box-sizing: border-box;
  }

  table {
    border-left: 1px solid gray;
    border-right: 1px solid gray;
    border-top: 1px solid gray;
    margin-bottom: 5px;
  }

  .table-heading {
    border: 1px solid gray;
    border-bottom: 0;
  }

  .table-footer {
    border: none;
    font-size: 13px;
    margin-bottom: 10px;
  }

  th {
    border-bottom: 2px solid black;
  }
  td {
    border-bottom: 1px solid gray;
  }
}

@mixin visit-button-text {
  font-size: 22px;
  letter-spacing: 0;
  text-align: center;
  font-family: Georgia-Italic;
  color: #ffffff;
  font-style: italic;
}

@mixin accordion-section {
  .accordion-section {
    display: none;
  }

  .active .accordion-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@mixin header-link {
  display: inline-block;
  padding: 0.5rem 8px;
  font-weight: normal;
  margin: 0;
  text-decoration: none;
  color: #808080;
  text-align: center;

  @media (min-width: 992px) {
    padding: 0.5rem 12px;
  }
}

@mixin hamburger-link {
  margin: 20px 0 10px;
  font-family: Rubik;
  font-weight: normal;
  font-size: 30px;
  letter-spacing: 0;
  text-align: center;
  color: #808080;
}
