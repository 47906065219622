.CollaborationSection {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  img {
    width: 200px;
    margin-bottom: 10px;
  }

  div.icon-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;

    div.icon-container {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}
