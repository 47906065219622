@import "../../scss/mixins";

.NavDropdown {
  @include header-link;
  position: relative;

  > div {
    cursor: pointer;

    @media (max-width: 767px) {
      @include hamburger-link;
    }

    &::after {
      content: url("./down_arrow.svg");
      display: inline-block;
      margin-left: 7px;
      position: relative;
      bottom: 2px;
      width: 10px;

      @media (max-width: 767px) {
        bottom: 4px;
      }
    }

    &.open::after {
      transform: scaleY(-1);
      bottom: unset;
      top: 2px;

      @media (max-width: 767px) {
        top: 4px;
      }
    }
  }

  .nav-dropdown-list {
    display: flex;
    flex-direction: column;
    background: white;

    &.closed {
      display: none;
    }

    @media (min-width: 768px) {
      position: absolute;
      bottom: -80px;
      left: 45%;
      transform: translateX(-50%);
      box-shadow:
        2px 2px 2px rgba(60, 64, 67, 0.15),
        -2px 2px 2px rgba(60, 64, 67, 0.15);
    }
  }
}
