.link-container {
  display: flex;
  justify-content: space-between;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  a {
    padding: 0 10px;
  }
}
