@import '../../scss/mixins';

#Veterans {
  @include app-max-size;
  @include accordion-section;

  @media (min-width: 992px) {
    align-items: flex-start;
  }

  .img-container {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    flex-wrap: wrap;
    justify-content: center;
    box-sizing: border-box;

    @media (min-width: 768px) {
      flex-direction: column;
      padding: 0 25px;
      width: 500px;
      flex-grow: 0;
      flex-shrink: 0;
    }
    
    p {
      margin-top: 18px;
      font-size: 18px;
      font-weight: 300;

      @media (max-width: 767px) {
        text-align: center;
        padding: 0 20px;
      }
    }

    img {
      max-width: 300px;

      @media (min-width: 992px) {
        max-width: 400px;
      }
    }
  }

  .accordion-section.with-table {
    @include with-table;
  }
}