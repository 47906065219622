@import "../../scss/mixins";

@mixin small-warning-text {
  font-weight: 300;
  font-family: Rubik;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
}

/* end of mixins */

#Home {
  @include app-max-size;

  .content {
    @media (min-width: 992px) {
      min-width: 480px;
      padding: 30px 0 0 20px;
    }

    .introduction {
      margin-top: 0;
      font-family: Archer;
      font-weight: bold;
      font-size: 48px;
      text-align: center;
      line-height: 1.25;
      color: #009cba;
      letter-spacing: 0;

      @media (min-width: 768px) {
        text-align: left;
      }

      @media (min-width: 992px) {
        font-size: 64px;
        width: 100%;
      }
    }

    #VisitButton {
      @media (min-width: 992px) {
        margin: 30px 0 40px;
        align-self: flex-start;
      }
    }
  }

  .disclosure {
    margin-bottom: 30px;

    p {
      @include small-warning-text;
      margin-top: 10px;
    }
  }
}
