@import '../../scss/mixins';

.flow-container {
  .arrow {
    width: 0;
    height: 0;
    border-top: 16px solid #a2c800;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    margin: auto;
  }

  .flowchart {
    margin: 10px 0;
    
    border: 1px solid #404040;
    display: flex;
    flex-direction: row;

    .flowchart-text {
      display: flex;
      flex-direction: column;
      padding: 8px 22px;
    }

    .rectangle {
      border-right: 1px solid #404040;
      flex-shrink: 0;
      width: 20px;
    }

    .light-orange { background-color: $light-orange; }
    .dark-orange { background-color: $dark-orange; }
    .light-green { background-color: $light-green; }
    .dark-green { background-color: $dark-green; }
    .light-blue { background-color: $light-blue; }
    .dark-blue { background-color: $dark-blue; }

    .bottom { margin-bottom: 30px; }
  }
}