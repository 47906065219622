@import "../../scss/mixins";

#VisitButton {
  @include visit-button-text;
  line-height: 22px;
  height: 90px;
  width: 290px;
  border-radius: 50px;
  background-color: #009cba;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px auto 20px;

  @media (min-width: 768px) {
    height: 80px;
    width: 350px;
    font-size: 24px;
    line-height: 24px;
  }

  .visit-text {
    margin-left: 25px;
    width: 200px;

    @media (min-width: 768px) {
      width: 265px;
    }
  }

  .arrow-right {
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 16px solid white;
    position: relative;
    margin: 0 5px;

    @keyframes wiggle {
      from {
        margin-left: 20px;
      }
      50% {
        margin-left: 20px;
      }
      57% {
        margin-left: 30px;
      }
      71% {
        margin-left: 20px;
      }
      78% {
        margin-left: 30px;
      }
      92% {
        margin-left: 20px;
      }
      to {
        margin-left: 20px;
      }
    }

    &.wiggle {
      animation-name: wiggle;
      animation-duration: 2s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-delay: 1.5s;
    }
  }
}
