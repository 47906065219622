.page-title {
  padding: 10px;
  font-family: Archer;
  font-weight: bold;
  font-size: 22px;
  color: #ffffff;
  text-align: left;
  align-self: flex-start;
  text-transform: uppercase;

  @media (min-width: 768px) {
    font-size: 25px;
    letter-spacing: 0.25px;
  }

  @media (min-width: 992px) {
    display: inline-block;
    font-size: 34px;
    letter-spacing: 0.3px;
  }

  &.blue { background: #009CBA; }
  &.dark-blue { background: #006477; }
  &.orange { background: #F37928; }
  &.green { background: #669900; }
}