#Accordion {
  width: 100%;
  height: 100%;
  margin: 30px 0;

  .accordion-button-and-section {
    margin-bottom: 10px;

    .arrow-down,
    .arrow-up {
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      margin-left: 5px;
    }

    .arrow-down {
      border-top: 16px solid white;
    }

    .arrow-up {
      border-bottom: 16px solid white;
    }

    .button {
      color: #ffffff;
      font-family: Archer;
      font-style: italic;
      font-weight: bold;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      min-height: 90px;
      text-align: left;
      width: 100%;
      padding: 10px 20px;
      font-size: 18px;

      @media (min-width: 375px) {
        font-size: 24px;
      }

      @media (min-width: 992px) {
        font-size: 30px;
      }
    }

    h1 {
      margin-top: 20px;
      font-size: 24px;
      font-weight: bold;
    }

    .button1 {
      background: #f5a623;
    }

    .button2 {
      background: #f37928;
    }

    .button3 {
      background: #a2c800;
    }

    .button4 {
      background: #669900;
    }

    .button5 {
      background: #83cfdd;
    }

    .button6 {
      background: #009cba;
    }

    .panel {
      width: 100%;
      max-height: 0;
      overflow: hidden;
      box-sizing: border-box;
      padding: 5px 0px;
      font-weight: 300;
      font-family: Rubik;
      font-size: 20px;
      letter-spacing: 0;
      line-height: 28px;
      vertical-align: middle;
      display: none;

      @media (min-width: 768px) {
        padding: 5px 18px;
        width: 100%;
      }

      @media (min-width: 992px) {
        font-size: 18px;
        line-height: 24px;
      }

      a {
        margin-left: 5px;
        text-decoration: underline;
        font-family: Rubik;
        color: #669900;
      }

      .text-part {
        margin-top: 1px;
        margin-bottom: 10px;
        align-self: flex-start;

        &.list-intro {
          font-size: 24px;
          margin-bottom: 10px;
        }

        a {
          margin-left: 0px;
        }

        ul,
        ol {
          margin-bottom: 10px;

          li {
            margin: 10px 0 10px 10px;
          }
        }
      }
    }

    .panel.active {
      width: 100%;
      padding: 20px 10px;
      max-height: 100%;
      display: block;

      @media (min-width: 992px) {
        padding: 30px 10px 10px;
        width: 100%;
      }
    }

    p ~ p {
      margin-top: 10px;
    }
  }
}
