@import "../../scss/mixins";

#Patients {
  @include app-max-size;
  @include accordion-section;

  @media (min-width: 992px) {
    align-items: flex-start;
  }

  img.tablet-menu {
    margin: 0px auto 10px;
    width: 100%;

    @media (min-width: 768px) {
      margin: 0 0 10px;
    }

    @media (min-width: 992px) {
      max-width: 500px;
      width: 40vw;
    }
  }

  > .column {
    margin-bottom: 30px;
  }

  .flow-container {
    margin: 20px 0;
  }

  #Accordion {
    margin-bottom: 0;
  }
}
