.app-background {
  display: flex;
  background-color: white;

  @media (min-width: 768px) {
    min-height: calc(100vh - 74px - 80px);
  }

  @media (min-width: 992px) {
    padding-left: 10px;
  }
}