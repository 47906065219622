@import '../../scss/mixins';

.ModalButton {
  @include visit-button-text;
  line-height: 2;
  background-color: #009CBA;
  box-sizing: border-box;
  white-space: nowrap;
  margin: 20px 0;
  border-radius: 20px;
  padding: 0 20px;
  white-space: wrap;
  box-sizing: border-box;
}