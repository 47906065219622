@import "../../scss/mixins";

#About {
  @include app-max-size;

  @media (min-width: 992px) {
    align-items: flex-start;
  }

  p {
    margin-bottom: 10px;
  }

  .page-intro-text {
    margin-top: 20px;
  }

  #Accordion + .page-intro-text {
    margin: 0 0 20px;
  }

  .partners {
    margin: 20px 20px 20px 0;

    @media (min-width: 768px) {
      margin-top: 20px;
      margin-left: 0;
    }

    @media (min-width: 992px) {
      padding-left: 30px;
    }
  }

  .image-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    flex-wrap: wrap;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }

    @media (min-width: 992px) {
      flex-direction: column;
      width: 500px;
      padding-right: 230px;
    }

    img {
      margin: 10px;
    }
  }

  img.partnership-image {
    width: 200px;
    margin-bottom: 20px;

    &#nitid-logo {
      width: 100px;
    }
  }

  img.credits-image {
    width: 220px;
    margin-bottom: 20px;
  }

  a {
    margin-left: 5px;
    text-decoration: underline;
    font-family: Rubik;
    color: hsl(80, 100%, 30%);
  }

  #Accordion .ModalButton {
    color: white;
    display: block;
    text-decoration: none;
    font-family: Georgia-Italic;
  }
}

.sec-4 .icon-container img {
  margin: 0 0 30px;
}

.overlay .modal.about-modal {
  width: 720px;

  @media (max-width: 767px) {
    width: calc(100vw - 40px);
  }
}
